import React, { useMemo, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";

import { useOnClickOutside } from "@onlinesales-ai/util-methods-v2";

import WithTooltip from "@onlinesales-ai/tooltip-v2";

import { Trans } from "react-i18next";

import "./SplitButton.less";

const SplitButtonWrapper = React.forwardRef(({
  selectedOption,
  onClick,
  optionsToShow,
  config,
  onItemClicked,
  isLoading,
  setSelectedOption,
  disabled,
  containerClass,
  leftAligned,
  enableActionableDropdown,
  mainButtonConfig,
  doNotShowSelectedStatus,
  ...restProps
}, ref) => {
  const [show, setShow] = useState(false);
  const { cta, icon, className } = useMemo(() => {
    if (!_isEmpty(mainButtonConfig)) {
      return mainButtonConfig;
    }
    return config[selectedOption];
  }, [selectedOption, mainButtonConfig]);

  const onClickItem = (newValue) => {
    onItemClicked(newValue);
    setSelectedOption(newValue);
    if (enableActionableDropdown) {
      onClick(newValue);
    }
  };

  const clickOutsideRef = useOnClickOutside(() => {
    setShow(false);
  });

  const mergeRefs = (el) => {
    if (ref) {
      if (typeof ref === "function") {
        ref(el);
      } else {
        ref.current = el;
      }
    }
    clickOutsideRef.current = el;
  };

  return (
    <SplitButton
      show={show}
      onToggle={(nextShow) => setShow(nextShow)}
      disabled={disabled}
      title={
        <span>
          {isLoading && <i className="icon icon-spinner icon-spin mr-2" />}
          {icon && <i className={`icon mr-2 ${icon}`} />}
          <span>
            <Trans>{cta}</Trans>
          </span>
        </span>
      }
      className={`${containerClass} split-button-group ${className} ${
        optionsToShow.length === 1 ? "no-options" : ""
      } ${leftAligned ? "left-aligned" : ""}`}
      onClick={() => {
        if (enableActionableDropdown) {
          setShow(!show);
          return;
        }
        onClick(selectedOption);
      }}
      noOptionClassName
      ref={mergeRefs}
      {...restProps}
    >
      {optionsToShow.map((option) => {
        const { label, value, description, disabled, disableTooltipText } = config[option];
        return (
          <WithTooltip title={disabled ? disableTooltipText : ""} placement="auto">
            <Dropdown.Item
              disabled={disabled}
              eventKey={value}
              onClick={() => onClickItem(value)}
              className={`${value === selectedOption ? "selected" : ""}`}
            >
              <div className="left-content">
                <div
                  className={classnames("label", {
                    "mb-0": !description,
                  })}
                >
                  <Trans>{label}</Trans>
                </div>
                <WithTooltip title={description}>
                  <div className="description ellipsis-text">
                    <Trans>{description}</Trans>
                  </div>
                </WithTooltip>
              </div>
              {(!doNotShowSelectedStatus && value === selectedOption) && (
                <div className="selected-status">
                  <span className="icon icon-check" />
                </div>
              )}
            </Dropdown.Item>
          </WithTooltip>
        );
      })}
    </SplitButton>
  );
});

SplitButtonWrapper.defaultProps = {
  defaultValue: "",
  optionsToShow: ["OPTION_1", "OPTION_2"],
  config: {
    OPTION_1: {
      value: "OPTION_1",
      label: "Option 1",
      description: "some description for option 1",
      className: "option1",
      cta: "Option 1",
    },
    OPTION_2: {
      value: "OPTION_2",
      label: "Option 2",
      description: "some description for option 2",
      className: "option2",
      cta: "Option 2",
    },
  },
  onClick: () => { },
  onItemClicked: () => {},
};

export default SplitButtonWrapper;
