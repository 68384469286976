import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@onlinesales-ai/input-v2";

import FilterWrapper from "../filterWrapper";

const RangeFilterComponent = ({
  value,
  onChange,
  tabKey,
  minInputProps,
  maxInputProps,
  error,
  onError,
}) => {
  const { t } = useTranslation();
  const greaterThanValue = value.greater_than || "";
  const lessThanValue = value.less_than || "";

  useEffect(() => {
    if (greaterThanValue > 0 && lessThanValue > 0 && lessThanValue < greaterThanValue) {
      onError({
        [tabKey]: "Invalid input. The minimum value should be less than the maximum value.",
      });
    } else if (greaterThanValue > 1000000000000 || lessThanValue > 1000000000000) {
      onError({
        [tabKey]: "Invalid input. The entered value is too high.",
      });
    } else {
      onError({
        [tabKey]: null,
      });
    }
  }, [greaterThanValue, lessThanValue]);

  return (
    <FilterWrapper error={error} tabKey={tabKey}>
      <div className="form-group price">
        <div className="range-input-wrapper">
          <Input
            type="number"
            value={greaterThanValue || ""}
            placeholder={t("Min")}
            onChange={(...param) => onChange(tabKey, "greater_than", ...param)}
            {...minInputProps}
            suffix={
              minInputProps.suffix && typeof minInputProps.suffix === "string"
                ? t(minInputProps.suffix)
                : minInputProps.suffix
            }
          />
        </div>
        <div className="dash">-</div>
        <div className="range-input-wrapper">
          <Input
            type="number"
            value={lessThanValue || ""}
            placeholder={t("Max")}
            onChange={(...param) => onChange(tabKey, "less_than", ...param)}
            {...maxInputProps}
            suffix={
              maxInputProps.suffix && typeof maxInputProps.suffix === "string"
                ? t(maxInputProps.suffix)
                : maxInputProps.suffix
            }
          />
        </div>
      </div>
    </FilterWrapper>
  );
};

RangeFilterComponent.defaultProps = {
  value: {},
  onChange: () => {},
};

export default RangeFilterComponent;
