import React, { useEffect, memo } from "react";
import { connect } from "react-redux";

import {
  getParameterByName,
  setCookie,
  populateEvent,
  getLSItem,
  removeLSItem,
} from "@onlinesales-ai/util-methods-v2";
import { COOKIES } from "@onlinesales-ai/constants-v2";

const fireIntercomEvents = (action, metaData) => {
  populateEvent("GOOGLE_CALLBACK_PAGE", "GOOGLE_CALLBACK||" + action, metaData);
};

const GoogleCallback = ({ loginModalType, defaultRoute }) => {
  const setCookies = (token) => {
    /**
     * Set UA_TOKEN and ubid with expire of 1 year
     */
    setCookie(COOKIES.UA_TOKEN, token, 365);
    setCookie(COOKIES.UBID, COOKIES.UBID_VALUE, 365);
  };

  const handleOauthBasedSignIn = () => {
    const token = getParameterByName("uaToken");
    if (token) {
      setCookies(token);
      fireIntercomEvents("GOOGLE_CALLBACK_WITH_TOKEN", { token });
    }

    if (loginModalType === "INLINE") {
      const redirectURLPath = getLSItem("tmpInlineRedirecUrlPath");
      removeLSItem("tmpInlineRedirecUrlPath");
      window.location.href = window.location.origin + (redirectURLPath || defaultRoute || "/");
      return;
    }

    const data = {
      success: !!token,
      token,
      responseType: getParameterByName("responseType"),
      errorCode: getParameterByName("errorCode"),
    };
    // var targetOrigin = window.location.origin;
    if (window.opener) {
      window.opener.postMessage(JSON.stringify(data), "*");
    } else {
      console.error("ERROR: window.opener is null, /callback must be opened from another window.");
    }
  };

  useEffect(() => {
    handleOauthBasedSignIn();
  }, []);

  return <></>;
};

const mapStateToProps = (state) => {
  const { loginModalType, defaultRoute } = state.DomainConfig;
  return {
    loginModalType,
    defaultRoute,
  };
};

export default connect(mapStateToProps)(memo(GoogleCallback));
