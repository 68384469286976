import React, { useState, useMemo, useEffect, useRef, Children, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";

import { components, Dropdown as DropdownVirtualized } from "@onlinesales-ai/dropdown-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { Input } from "@onlinesales-ai/input-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { useDebounce } from "@onlinesales-ai/util-methods-v2";
import { getEntityInfo, parseProcessedEntityId, entityTypeEnum, entityTypeBusineessNameEnum, getEntityMetadata, businessDefinitionEnum } from "@onlinesales-ai/ott-common-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import {
  changeEntityId as changeEntityIdAction,
  fetchEntitiesData as fetchEntitiesDataAction,
  onFilterEntityBySearchText as onFilterEntityBySearchTextAction,
} from "src/store/application/actions";

import NewEntityCreation from "../newEntityCreation";

import "./index.less";

const NON_ACTIONING_ITEM = {
  DUMMY_LOADING: "DUMMY_LOADING",
  DUMMY_SEARCH: "DUMMY_SEARCH",
  DUMMY_EMPTY_STATE: "DUMMY_EMPTY_STATE",
  ADD_NEW_BRAND: "ADD_NEW_BRAND",
};

const SEARCH_FETCH_LIMIT = 1000;

const CustomMenuList = ({ selectProps, ...props }) => {
  const {
    onMenuInputFocus,
    searchModeState,
    onSearchTextChange,
    rootEntityId,
    agencyId,
    currentListEntityId,
    currentEntityBreadCrumbs,
    onSearchIconClick,
    onBreadCrumbClick,
    isEnableSearchModeOnly,
    refSearchInput,
    isAllowNewEntityCreation,
  } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  let breadCrumbToUse = [...currentEntityBreadCrumbs];
  if (rootEntityId === `${entityTypeEnum.AGENCY}_${agencyId}`) {
    breadCrumbToUse = [
      {
        entityId: rootEntityId,
        entityName: "All Businesses",
      },
      ...breadCrumbToUse,
    ];
  }

  return (
    <div className={`hriday-menu ${isAllowNewEntityCreation ? "allow-entity-creation" : ""}`}>
      <div className="entity-search-and-navigator">
        {
          searchModeState.isEnabled && (
            <Input
              autoCorrect="off"
              autoComplete="off"
              spellCheck="false"
              type="text"
              trimOnBlur={false}
              placeholder="Search"
              ref={refSearchInput}
              inputWrapperClassName="border-padding entity-search-input"
              value={searchModeState.searchText}
              onChange={onSearchTextChange}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onKeyUp={(e) => {
                e.stopPropagation();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              {...ariaAttributes}
            />
          )
        }
        {
          breadCrumbToUse.length > 0 && !searchModeState.isEnabled && (
            <div className="left menu-breadcrumb-navigation">
              {breadCrumbToUse?.map((item) => {
                return (
                  <div className="menu-breadcrumb-item">
                    {
                      item.entityId === currentListEntityId ? (
                        <span className="menu-label">{item.entityName}</span>
                      ) : (
                        <>
                          <a
                            href="javascript:void(0)"
                            className="menu-label" 
                            onClick={(e) => onBreadCrumbClick(e, item.entityId)}>
                              {item.entityName}
                          </a>
                          <span className="icon icon-angle-right ml-2 mr-2" />
                        </>
                      )
                    }
                  </div>
                );
              })}
            </div>
          )
        }
        <div
          className="right"
          onClick={onSearchIconClick}
        >
          <div className="main-search-icon">
            <span className={`icon ${(!isEnableSearchModeOnly && searchModeState.isEnabled) ? "icon-close1" : "icon-search-2"}`} />
          </div>
        </div>
      </div>
      <components.MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const MultiLevelDropdown = ({
  isDarkMode,
  selectedEntityId,
  selectedEntityType,
  entityInfo,
  entityMetadata,
  entitiesFetchState,
  entitiesFetchInProgress,
  onEntityChange,
  fetchEntitiesData,
  onFilterEntityBySearchText,
  rootEntityId,
  agencyId,
  onChangeHandler,
  selectableEntityType,
  isEnableSearchModeOnly,
  isEditable,
  isAllowNewEntityCreation,
  showEntityType,
  onOptionLoading,
  dropdownContainerClassName,
  placeholder,
  showUpdateBillingIfUserInvalid,
  isShowBreadCrumbExpandForAdvertiser,
}) => {
  const { redirectUrl } = useContext(GlobalContext);
  const timestampRef = useRef(new Date().valueOf());
  const refSearchInput = useRef(null);
  const containerRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const [searchModeState, setSearchModeState] = useState(() => ({
    isEnabled: isEnableSearchModeOnly,
    searchText: "",
    resultEntityIds: [],
    offset: 0,
    isAllFetched: false,
    isFetchInProgress: false,
    fetchCounter: 0,
  }));
  const [isShowNewEntityCreationDrawer, setIsShowNewEntityCreationDrawer] = useState(false);
  const searchTerm = useDebounce(searchModeState.searchText, 500);

  const onDomClick = (e) => {
    const menu = containerRef?.current?.querySelector(".Select__menu");

    if (
      !menu ||
      (!containerRef?.current?.contains(e.target) && !menu.contains(e.target))
    ) {
      setIsSearchFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const getBreadCrumbs = (entityId) => {
    let currentId = entityId;
    let breadCrumbItems = [];

    while (true) {
      const currentItem = getEntityInfo(entityInfo, currentId);
      if (currentItem.entityId) {
        breadCrumbItems = [
          {
            entityId: currentItem.entityId,
            entityType: currentItem.entityType,
            entityName: currentItem.entityName,
          },
          ...breadCrumbItems,
        ];

        if (!currentItem.parentMCCId) {
          break;
        }

        currentId = `${entityTypeEnum.MCC}_${currentItem.parentMCCId}`;
      } else {
        break;
      }
    }

    return breadCrumbItems;
  };

  const selectedEntityInfo = useMemo(() => {
    if (!selectedEntityType || !selectedEntityId) {
      return {};
    }

    const storedInfo = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);

    const breadCrumbItems = getBreadCrumbs(`${selectedEntityType}_${selectedEntityId}`);

    storedInfo.breadCrumbText = breadCrumbItems.map((i) => i.entityName).join(" > ");

    return storedInfo;
  }, [selectedEntityId, selectedEntityType, entityInfo]);

  const [currentListEntityId, setCurrentListEntityId] = useState(null);

  useEffect(() => {
    const id = selectedEntityInfo?.parentMCCId ? `${entityTypeEnum.MCC}_${selectedEntityInfo?.parentMCCId}` : rootEntityId;
    if (!entitiesFetchState[id]?.offset &&
        !entitiesFetchState[id]?.isAllFetched &&
        !entitiesFetchState[id]?.isFetchInProgress) {
      const entityDetails = parseProcessedEntityId(id) || {};
      const mccId = (entityDetails.entityType === entityTypeEnum.MCC) ? entityDetails.entityId : null; 
      fetchEntitiesData(mccId);
    }

    setCurrentListEntityId(id);
  }, [selectedEntityInfo?.parentMCCId, rootEntityId]);

  useEffect(() => {
    if (searchModeState.isEnabled && (isEnableSearchModeOnly || !!searchTerm)) {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        offset: 0,
        resultEntityIds: [],
        isAllFetched: false,
        isFetchInProgress: true,
        fetchCounter: (searchModeState?.fetchCounter || 0) + 1,
      }));
    }
  }, [searchModeState.isEnabled, searchTerm]);

  const fetchSearchResults = async () => {
    try {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        isFetchInProgress: true,
      }));

      let mccId = null;
      if (rootEntityId !== `${entityTypeEnum.AGENCY}_${agencyId}`) {
        mccId = parseProcessedEntityId(rootEntityId).entityId;
      }

      const searchResultIds = await onFilterEntityBySearchText({
        agencyId,
        mccId,
        searchQuery: encodeURIComponent(searchModeState.searchText),
        offset: searchModeState.offset,
        entityType: selectableEntityType,
        limit: SEARCH_FETCH_LIMIT,
        selectors: [
          "entityId",
          "entityName",
          "entityAlias",
          "entityType",
          "statusType",
          "depth",
          "metadata.approvalStatus",
          "metadata.businessDefinition",
          "metadata.customLabel1",
          "hierarchy.entityId",
          "hierarchy.entityType",
          "hierarchy.entityName",
          "hierarchy.entityAlias",
          // @deep #todo add selectors
        ],
      }, {
        abortId: `entity_search_result_${timestampRef.current}`,
      });

      setSearchModeState((currentVal) => ({
        ...currentVal,
        resultEntityIds: _uniq([
          ...(currentVal.resultEntityIds || []),
          ...searchResultIds,
        ]),
        isFetchInProgress: false,
        isAllFetched: (searchResultIds?.length !== SEARCH_FETCH_LIMIT),
      }));
    } catch (e) {
      // Not thrown from action
    }
    setIsFetchComplete(true);
  };

  useEffect(() => {
    if (searchModeState.fetchCounter > 0) {
      fetchSearchResults();
    }
  }, [searchModeState.fetchCounter]);

  const currentEntityFetchState = useMemo(() => {
    if (currentListEntityId) {
      return entitiesFetchState[currentListEntityId] || {};
    }

    return {};
  }, [currentListEntityId, entitiesFetchState]);

  const currentEntityBreadCrumbs = useMemo(() => {
    return getBreadCrumbs(currentListEntityId);
  }, [currentListEntityId, entityInfo]);

  const currentEntityBreadCrumbText = useMemo(() => {
    return currentEntityBreadCrumbs.map((i) => i.entityName).join(" > ");
  }, [currentEntityBreadCrumbs]);

  const entityOptions = useMemo(() => {
    const entityIds =
      searchModeState.isEnabled ?
        (searchModeState.resultEntityIds || []) :
        (currentEntityFetchState?.children || []);
    return entityIds.map((id) => {
      const currentEntityInfo = getEntityInfo(entityInfo, id);
      // const currentEntityMetadata = getEntityMetadata(entityMetadata, id);

      currentEntityInfo.hierarchy = getBreadCrumbs(currentEntityInfo.entityId);

      return {
        label: currentEntityInfo.entityName,
        value: id,
        entityType: currentEntityInfo.entityType,
        hierarchy: currentEntityInfo.hierarchy,
        businessDefinition: currentEntityInfo?.metadata?.businessDefinition || "CLIENT",
        isBrandValidated: true,
        // currentEntityInfo.entityType === "CLIENT" &&
        // currentEntityMetadata?.supportingDocumentStatus === "APPROVED",
      };
    });
  }, [
    currentEntityFetchState.children,
    searchModeState.resultEntityIds,
    searchModeState.isEnabled,
  ]);

  const options = useMemo(() => {
    let retnVal = [];

    retnVal = [
      ...retnVal,
      ...entityOptions,
    ];

    const isFetchInProgress = (
      searchModeState.isEnabled ?
        searchModeState.isFetchInProgress :
        currentEntityFetchState.isFetchInProgress
    );

    let isEmpty = false;
    let isLoaderWithEmptyOption = false;

    if (isFetchInProgress) {
      isLoaderWithEmptyOption = (retnVal.length === 0);
      retnVal.push({
        label: "Loading...",
        value: NON_ACTIONING_ITEM.DUMMY_LOADING,
        isLazyLoadStatusOption: retnVal.length > 0,
        isLoaderWithEmptyOption,
      });
    }

    if (!isFetchInProgress &&
        !searchModeState.isEnabled &&
        !currentEntityFetchState.children?.length
    ) {
      retnVal.push({
        label: currentEntityFetchState?.fetchErrorMsg || "No account found",
        value: NON_ACTIONING_ITEM.DUMMY_EMPTY_STATE,
        isEmptyState: true,
      });

      isEmpty = true;
    }

    if (!isFetchInProgress &&
        searchModeState.isEnabled &&
        !searchModeState.resultEntityIds?.length
    ) {
      retnVal.push({
        label: "No Search Result Found",
        value: NON_ACTIONING_ITEM.DUMMY_EMPTY_STATE,
        isEmptyState: true,
      });

      isEmpty = true;
    }

    if (isAllowNewEntityCreation && !isEmpty && !isLoaderWithEmptyOption) {
      retnVal.push({
        label: "Add New Brand",
        value: NON_ACTIONING_ITEM.ADD_NEW_BRAND,
        isAddNewEntity: true,
        className: "add-brand-item",
      });
    }

    return retnVal;
  }, [entityOptions, currentEntityFetchState, searchModeState]);

  useEffect(() => { 
    if (onOptionLoading && isFetchComplete) {
      onOptionLoading(options);
    }
  }, [isFetchComplete]);

  useEffect(() => {
    if (searchModeState.isEnabled) {
      refSearchInput?.current?.focus();
    }
  }, [searchModeState.isEnabled]);

  const onChangeOption = (newVal) => {
    if (NON_ACTIONING_ITEM[newVal]) {
      return;
    }

    setIsSearchFocused(false);

    const newEntityInfo = getEntityInfo(entityInfo, newVal);

    if (selectableEntityType && selectableEntityType !== newEntityInfo?.entityType) {
      return;
    }

    const parsedData = parseProcessedEntityId(newEntityInfo?.entityId);

    if (selectedEntityId && selectedEntityId === parsedData?.entityId) {
      return;
    }

    const {
      entityId: newEntityId,
      entityType: newEntityType,
    } = parseProcessedEntityId(newVal);
    if (onChangeHandler && typeof onChangeHandler === "function") {
      onChangeHandler(newEntityId, newEntityType);
    } else {
      onEntityChange(newEntityId, newEntityType);
    }
  };

  const handleMenuScrollToBottom = () => {
    if (searchModeState.isEnabled) {
      if (!searchModeState.isAllFetched && !searchModeState.isFetchInProgress) {
        setSearchModeState((currentVal) => ({
          ...currentVal,
          offset: searchModeState?.resultEntityIds?.length,
          fetchCounter: (searchModeState?.fetchCounter || 0) + 1,
        }));
      }
    } else if (!_isEmpty(currentEntityFetchState) && !currentEntityFetchState.isAllFetched) {
      const entityDetails = parseProcessedEntityId(currentListEntityId) || {};
      const mccId = (entityDetails.entityType === entityTypeEnum.MCC) ? entityDetails.entityId : null; 
      fetchEntitiesData(mccId);
    }
  };

  const onEntityDrillDown = (val) => {
    if (!entitiesFetchState[val]?.offset &&
      !entitiesFetchState[val]?.isFetchInProgress) {
      const entityDetails = parseProcessedEntityId(val) || {};
      const mccId = (entityDetails.entityType === entityTypeEnum.MCC) ? entityDetails.entityId : null; 
      fetchEntitiesData(mccId);
    }
    setCurrentListEntityId(val);
  };

  const onClickHandlerWithoutPropogation = (e) => {
    e.stopPropagation();
  };

  const onBreadCrumbClick = (e, entityId) => {
    e.stopPropagation();
    onEntityDrillDown(entityId);
  };

  const onSearchIconClick = (e) => {
    e.stopPropagation();
    if (isEnableSearchModeOnly) {
      refSearchInput?.current?.focus();
    } else {
      setSearchModeState((currentVal) => ({
        ...currentVal,
        isEnabled: !currentVal.isEnabled,
      }));
    }
  };

  const onSearchTextChange = (val) => {
    setSearchModeState((currentVal) => ({
      ...currentVal,
      searchText: val,
    }));
  };

  const onClickUpdateDetails = (e, val) => {
    e.stopPropagation();
    const { entityId, entityType } = parseProcessedEntityId(val?.value);

    redirectUrl(
      `__APPEND__/drawer/BUSINESS_DETAILS?entityId=${entityId}&entityType=${entityType}`,
    );
  };

  const onCreateNewEntity = (e) => {
    e.stopPropagation();
    setIsShowNewEntityCreationDrawer(true);
  };

  const onNewEntityCreation = ({ newEntityType, newEntityId }) => {
    if (searchModeState.isEnabled) {
      if (!selectableEntityType || (selectableEntityType === newEntityType)) {
        setSearchModeState((currentVal) => ({
          ...currentVal,
          resultEntityIds: _uniq([
            `${newEntityType}_${newEntityId}`,
            ...(currentVal.resultEntityIds || []),
          ]),
        }));
      }
    }
  };

  const getOptionDOM = (option) => {
    if (option.isEmptyState) {
      return (
        <div className="no-options-dom">
          <div className="no-option-icon">
            <span className="icon icon-no-results-3" />
          </div>
          {
            searchModeState.isEnabled ? (
              <>
                <div className="info-text">No result found</div>
                <div className="placeholder-text">Try different keyword</div>
                {
                  isAllowNewEntityCreation &&
                  <Button
                    icon="icon-plus-2"
                    className="cta-button"
                    link
                    onClick={onCreateNewEntity}
                  >
                    Create Brand
                  </Button>
                }
              </>
            ) : (
              <>
                <div className="info-text">{option?.label || "No account found"}</div>
                {
                  isAllowNewEntityCreation &&
                  <Button
                    icon="icon-plus-2"
                    className="cta-button"
                    link
                    onClick={onCreateNewEntity}
                  >
                    Create Brand
                  </Button>
                }
              </>
            )
          }
        </div>
      );
    }

    if (option.isAddNewEntity) {
      return (
        <Button
          icon="icon-plus-2"
          className="cta-button"
          link
          onClick={onCreateNewEntity}
        >
          {option.label}
        </Button>
      );
    }

    if (option.isLazyLoadStatusOption) {
      return (
        <AsyncImage
          imgClassName="lazy-loading-image"
          imgSrc="https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif" />
      );
    }

    if (option.isLoaderWithEmptyOption) {
      return (
        <div className="no-options-dom">
          {loaderWhite()}
          <div className="info-text">Loading...</div>
        </div>
      );
    }

    const isOptionNotSelectable =
      (selectableEntityType && option.entityType !== selectableEntityType);

    let showBreadCrumbExpand =
      option.entityType === entityTypeEnum.MCC && !searchModeState.isEnabled;

    if (option.businessDefinition === businessDefinitionEnum.ADVERTISER) {
      showBreadCrumbExpand =
        isShowBreadCrumbExpandForAdvertiser &&
        option.entityType === entityTypeEnum.MCC &&
        !searchModeState.isEnabled;
    }
    const isBrandInValid = showUpdateBillingIfUserInvalid && !option.isBrandValidated;

    const isChildrenAvailable = showEntityType || showBreadCrumbExpand || isBrandInValid;

    const onClickOption = (e) => {
      if (isBrandInValid) {
        e.stopPropagation();
      }
    };

    return (
      <div className={`option-item ${searchModeState.isEnabled && "is-seach-active"}`} onClick={onClickOption}>
        <div
          className={`left ${isOptionNotSelectable ? "not-selectable" : ""} ${isBrandInValid ? "disabled" : ""}`}
          onClick={isOptionNotSelectable ? onClickHandlerWithoutPropogation : undefined}>
          {searchModeState.isEnabled &&
            <div className="highlight-search-label d-align-between">
              {option?.label}
            </div>}
          <div className={`left-inner ${isBrandInValid ? "disabled" : ""}`}>
            {
              (searchModeState.isEnabled && option?.hierarchy?.length > 0) ? (
                option?.hierarchy?.map((subItem, index) => {
                  return (
                    <span>
                      {
                        index > 0 &&
                        <>
                          &nbsp;
                          <span className="icon icon-angle-right ml-2 mr-2" />
                        </>
                      }
                      {subItem.entityName}
                    </span>
                  );
                })
              ) : (
                <div className="label d-align-between">
                  {option?.label}
                </div>
              )
            }
          </div>
        </div>
        {isChildrenAvailable ? (
          <div
            className="right d-align-center"
          >
            {showEntityType &&
              <span className="sub-text-label">
                {entityTypeBusineessNameEnum[option?.businessDefinition]}
              </span>}
            {showBreadCrumbExpand && (
              <div className="item-arrow" onClick={(e) => onBreadCrumbClick(e, option?.value)}>
                <span
                  className="icon icon-angle-right"
                />
              </div>
            )}
            {isBrandInValid ? (
              <WithTooltip title="Update Details" placement="right" enableBoxShadow>
                <Button link icon="icon-edit-filled" onClick={(e) => onClickUpdateDetails(e, option)} className="update-button" />
              </WithTooltip>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const CustomOption = (props) => {
    const { data } = props;
    return (
      <components.Option {...props} >
        {getOptionDOM(data)}
      </components.Option>
    );
  };

  const ValueContainer = ({ children, ...props }) => {
    const notAllowedTypes = ["SingleValue"];
    
    if (selectedEntityInfo.breadCrumbText) {
      notAllowedTypes.push("Placeholder");
    }

    const allowedChildren = Children.map(children, (child) => {
      return child && !notAllowedTypes.includes(child.type.name) ? child : null;
    });
    
    return (
      <components.ValueContainer {...props}>
        {allowedChildren}
        {selectedEntityInfo.breadCrumbText}
      </components.ValueContainer>
    );
  };

  if (!entitiesFetchInProgress) {
    const newEntityParentInfo = parseProcessedEntityId(searchModeState.isEnabled ? rootEntityId : currentListEntityId)

    return (
      <>
        <NewEntityCreation
          isOpen={isShowNewEntityCreationDrawer}
          onHide={() => setIsShowNewEntityCreationDrawer(false)}
          jobDoneCallback={() => setIsShowNewEntityCreationDrawer(false)}
          title={currentEntityBreadCrumbText ? `Create New Brand for "${currentEntityBreadCrumbText}"` : "Create New Brand"}
          onNewEntityCreation={onNewEntityCreation}
          parentMCCId={newEntityParentInfo?.entityType === entityTypeEnum.MCC ? newEntityParentInfo?.entityId : null}
        />
        <div
          className={`os-multi-level-dropdown-wrapper ${isDarkMode ? "dark-mode" : ""}`}
          ref={containerRef}
        >
          {
            isEditable ? (
              <DropdownVirtualized
                inputId={`entity-select-dd-${timestampRef.current}`}
                components={{
                  ValueContainer,
                  Option: CustomOption,
                  MenuList: CustomMenuList,
                }}
                containerClassName={dropdownContainerClassName}
                isSearchable={false}
                hideSelectedOptions={false}
                value={`${selectedEntityType}_${selectedEntityId}`}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                onChange={onChangeOption}
                onMenuInputFocus={() => setIsSearchFocused(true)}
                isLoading={entitiesFetchInProgress}
                options={options}
                searchModeState={searchModeState}
                onSearchTextChange={onSearchTextChange}
                rootEntityId={rootEntityId}
                agencyId={agencyId}
                currentEntityBreadCrumbs={currentEntityBreadCrumbs}
                onSearchIconClick={onSearchIconClick}
                onBreadCrumbClick={onBreadCrumbClick}
                isEnableSearchModeOnly={isEnableSearchModeOnly}
                refSearchInput={refSearchInput}
                currentListEntityId={currentListEntityId}
                menuIsOpen={isSearchFocused || undefined}
                isFocused={isSearchFocused || undefined}
                isAllowNewEntityCreation={isAllowNewEntityCreation}
                menuPosition="fixed"
                placeholder={placeholder}
              />
            ) : (
              <div className="non-edit-mode-text">{selectedEntityInfo.breadCrumbText || ""}</div>
            )
          }
        </div>
      </>
    );
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    selectedEntityId,
    selectedEntityType,
    entityInfo,
    entityMetadata,
    entitiesFetchState,
    entitiesFetchInProgress,
    agencyId,
  } = state.Application || {};

  return {
    selectedEntityId,
    selectedEntityType,
    entityInfo,
    entityMetadata,
    entitiesFetchState,
    entitiesFetchInProgress,
    rootEntityId: `${entityTypeEnum.AGENCY}_${agencyId}`,
    agencyId,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onEntityChange: changeEntityIdAction,
      fetchEntitiesData: fetchEntitiesDataAction,
      onFilterEntityBySearchText: onFilterEntityBySearchTextAction,
    },
    dispatch,
  );
};

MultiLevelDropdown.defaultProps = {
  isDarkMode: false,
  isEditable: true,
  showEntityType: false,
  isShowBreadCrumbExpandForAdvertiser: true,
  // rootEntityId => To define a root under which the hierarchy is to be rendered
  // selectedEnitityId => For a controlled dropdown pass selected entity id
  // selectedEntityType => For a controlled dropdown pass selected entity type
  onChangeHandler: null, // Callback when entity selection changes, to be used when controlled from outside,
  selectableEntityType: null, // MCC, CLIENT,
  isEnableSearchModeOnly: false, //To enable exhaustive list of entities. This will show only search mode and load with empty search text as well
  isAllowNewEntityCreation: false,
  showUpdateBillingIfUserInvalid: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLevelDropdown);
