import React from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import { Checkbox } from "@onlinesales-ai/checkbox-v2";

import FormWrapper from "../../FormWrapper";

class BooleanCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onChange, dataKey, defaultValue } = this.props;
    const value = this.getValue();
    if (value === undefined) {
      onChange({
        [dataKey]: defaultValue || false,
      });
    }
  }

  getValue = () => {
    const { dataKey, formValues, getValue } = this.props;

    if (getValue) {
      return getValue(this.props);
    }

    return _get(formValues, dataKey);
  };

  onChangeCheckbox = (value) => {
    const { onChange, dataKey } = this.props;
    onChange({
      [dataKey]: value || false,
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      name,
      layout,
      nonEditableComponent,
      renderNonEditableChildren,
      defaultValue,
      id,
      hideLabel,
      ...rest
    } = this.props;

    const value = this.getValue();

    return (
      <FormWrapper
        formGroupClassName={formGroupClassName}
        labelColumns={labelColumns}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
      >
        {isEditable ? (
          <>
            <Checkbox
              {...rest}
              key="checkbox_boolean"
              disabled={disabled}
              name={name}
              label={name}
              id={`${id}_${Math.random()}_${new Date().getTime()}`}
              value={value || defaultValue}
              checked={value || defaultValue}
              onChange={(checked) => this.onChangeCheckbox(checked, value)}
              hideLabel={hideLabel}
            />
            {/* <CheckboxGroup
              name={optionName}
              groupValue={[value]}
              defaultValue={[defaultValue]}
              data={[
                {
                  label: optionName,
                  value: true,
                },
              ]}
              onChange={this.onChangeCheckbox}
              disabled={disabled}
              layout={layout}
            /> */}
          </>
        ) : (
          <div className="non-edit-mode-text">
            {nonEditableComponent ||
              (renderNonEditableChildren ? renderNonEditableChildren(value) : null) || (
                <Checkbox
                  {...rest}
                  key="checkbox_boolean"
                  disabled
                  name={name}
                  label={name}
                  id={`${id}_${Math.random()}_${new Date().getTime()}`}
                  value={value}
                  checked={value}
                  onChange={() => {}}
                />
            )}
          </div>
        )}
      </FormWrapper>
    );
  }
}

BooleanCheckbox.defaultProps = {
  name: "form-checkbox",
  dataKey: null,
  defaultValue: false,
  title: "",
  labelColumns: 3,
  formGroupClassName: "form-component-checkbox",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(BooleanCheckbox);
