export const COOKIES = {
  UA_TOKEN: "UA_TOKEN",
  SHOP: "shop",
  USER: "user",
  UBID: "ubid",
  SELECTED_ENTITY_ID: "selectedEntityIdNew",
  UBID_VALUE: "unique12345",
};

export const ToastTypeEnum = {
  DEFAULT: {
    key: "DEFAULT",
    classname: "default-toast",
  },
  INFO: {
    key: "INFO",
    classname: "info-toast",
  },
  WARNING: {
    key: "WARNING",
    classname: "warning-toast",
  },
  ERROR: {
    key: "ERROR",
    classname: "error-toast",
  },
  SUCCESS: {
    key: "SUCCESS",
    classname: "success-toast",
  },
};

export const applicationTypesEnum = {
  "ACQUIRE": {
      key: "ACQUIRE",
      displayName: "Acquire"
  },
  "GRAVITI": {
      key: "GRAVITI",
      displayName: "Graviti"
  },
  "ONSITE": {
      key: "ONSITE",
      displayName: "Onsite"
  }
};

export const CLIENT_AGGREGATOR_CONFIG = {
  displayName: "All Clients",
  value: "CLIENT_AGGREGATOR"
};

export const DefaultOnboardingConfig = {
  clientId: CLIENT_AGGREGATOR_CONFIG.value,
  appConfigs: {},
  application: "sokratiShopsEventConsumer",
  marketplaceStoreDetails: {
    id: CLIENT_AGGREGATOR_CONFIG.value,
    name: CLIENT_AGGREGATOR_CONFIG.displayName,
  },
  userId: 1234,
  apps: {
    ACQUIRE: {
      finalStatus: "ACTIVE",
    },
    ONSITE: { finalStatus: "IN_PROGRESS" },
    GRAVITI: {
      finalStatus: "IN_PROGRESS",
    },
  },
  webtourStatus: {},
};

export const ReportingDataSource = {
  ONLINESALES: {
    key: "ONLINESALES",
    value: "ONLINESALES",
    displayName: "OnlineSales.ai",
  },
  VENDOR: {
    key: "VENDOR",
    value: "VENDOR",
    displayName: "Channels",
  },
};

export const LoginMethodToTypes = {
  ONLY_GOOGLE_SIGN_IN: ["only_google_sign_in"],
  LOGIN_WITH_CREDENTIALS: ["login_with_credentials"],
  LOGIN_WITH_OTP: ["login_with_otp"],
  LOGIN_WITH_GOOGLE_AND_CREDENTIALS: ["only_google_sign_in", "login_with_credentials"],
  LOGIN_WITH_GOOGLE_AND_OTP: ["only_google_sign_in", "login_with_otp"],
  LOGIN_WITH_GOOGLE_AND_CREDENTIALS_AND_OTP: ["only_google_sign_in", "login_with_credentials", "login_with_otp"],
  LOGIN_WITH_CREDENTIALS_AND_OTP: ["login_with_credentials", "login_with_otp"],
  HOTSTAR_LOGIN: ["hotstar_login"],
  ERROR_LOGIN: ["error_login"],
  LOGIN_WITH_AUTH_ZERO: ["login_with_auth_zero"],
  LOGIN_WITH_AUTH_ZERO_AND_CREDENTIALS: ["login_with_auth_zero", "login_with_credentials"],
  LOGIN_WITH_AUTH_ZERO_AND_OTP: ["login_with_auth_zero", "login_with_otp"],
  LOGIN_WITH_GOOGLE_AUTH_ZERO_AND_CREDENTIALS: ["only_google_sign_in", "login_with_auth_zero", "login_with_credentials"],
  LOGIN_WITH_GOOGLE_AND_AUTH_ZERO: ["only_google_sign_in", "login_with_auth_zero"],
};

export const LoginMethod = {
  ONLY_GOOGLE_SIGN_IN: "ONLY_GOOGLE_SIGN_IN",
  LOGIN_WITH_CREDENTIALS: "LOGIN_WITH_CREDENTIALS",
  LOGIN_WITH_OTP: "LOGIN_WITH_OTP",
  LOGIN_WITH_GOOGLE_AND_CREDENTIALS: "LOGIN_WITH_GOOGLE_AND_CREDENTIALS",
  LOGIN_WITH_GOOGLE_AND_OTP: "LOGIN_WITH_GOOGLE_AND_OTP",
  LOGIN_WITH_GOOGLE_AND_CREDENTIALS_AND_OTP: "LOGIN_WITH_GOOGLE_AND_CREDENTIALS_AND_OTP",
  LOGIN_WITH_CREDENTIALS_AND_OTP: "LOGIN_WITH_CREDENTIALS_AND_OTP",
  LOGIN_WITH_AUTH_ZERO: "LOGIN_WITH_AUTH_ZERO",
  LOGIN_WITH_AUTH_ZERO_AND_CREDENTIALS: "LOGIN_WITH_AUTH_ZERO_AND_CREDENTIALS",
  LOGIN_WITH_AUTH_ZERO_AND_OTP: "LOGIN_WITH_AUTH_ZERO_AND_OTP",
  LOGIN_WITH_GOOGLE_AUTH_ZERO_AND_CREDENTIALS: "LOGIN_WITH_GOOGLE_AUTH_ZERO_AND_CREDENTIALS",
  LOGIN_WITH_GOOGLE_AND_AUTH_ZERO: "LOGIN_WITH_GOOGLE_AND_AUTH_ZERO",
  HOTSTAR_LOGIN: "HOTSTAR_LOGIN",
  ERROR_LOGIN: "ERROR_LOGIN",
};

export const ForgotPasswordMethod = {
  HOTSTAR_FORGOT_PASSWORD: "HOTSTAR_FORGOT_PASSWORD",
};

export const CreatePasswordMethod = {
  HOTSTAR_CREATE_PASSWORD: "HOTSTAR_CREATE_PASSWORD",
};

export const callToActionTypes = {
  REDIRECT_TO_URL: "REDIRECT_TO_URL",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_INTERCOM: "OPEN_INTERCOM",
  REDIRECT_TO_ALL_OUTLET_URL: "REDIRECT_TO_ALL_OUTLET_URL",
  PLATFORM_EVENT: "PLATFORM_EVENT",
  REDIRECT_TO_PULSE: "REDIRECT_TO_PULSE",
};
