import proxy from "./proxy";
import proxyStaging from "./proxy-staging";
import proxyTest from "./proxy-test";

export const DEFAULT_PROXY_KEY = "default";

export const getProxyKey = () => {
  const domainName = window.location.hostname;
  const domainMapping = {
    "hotstar.onlinesales.ai": "staging",
    "adsmanager-staging.hotstar.com": "staging",
    "hotstar-staging.onlinesales.ai": "test",
    "hotstar-sd.onlinesales.ai": "staging",
    "publisher-demo.onlinesales.ai": "staging",
    "osmos-staging-12.onlinesales.ai": "staging",
    "osmos-staging-3.onlinesales.ai": "default",
  };

  if (domainMapping[domainName]) {
    return domainMapping[domainName];
  }

  return DEFAULT_PROXY_KEY;
};

export default {
  default: proxy,
  test: proxyTest,
  staging: proxyStaging,
};
