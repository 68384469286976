import BaseClient from "../baseClient";

const servicePrefix = "/reviewService/";

class ReviewService extends BaseClient {
  static getHandledErrorMessages() {
    return {
      MAX_PACKAGE_USED_EXHAUSTED_FOR_BRAND: `The maximum package quantity usage for {{brandName}} has been reached.`,
      MAX_PACKAGE_USED_EXHAUSTED: "The maximum package usage has been exhausted.",
      INVALID_PACKAGE_QUANTITY_LIMIT_FOR_BRAND: `Only {{availableBookingPerBrand}} of {{packageName}} left for {{brandName}}.`,
      INVALID_PACKAGE_QUANTITY_LIMIT: `Only {{availableBookingOverall}} remaining for {{packageName}}.`,
      RECORD_DUPLICATION:
        "It seems this entry already exists. Please update your name to make it unique.",
    };
  }

  static getErrorMessages(params) {
    let errorCodes = {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      UNAUTHORIZED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      RSE_INTERNAL_SERVER_ERROR:
        "Looks like we are facing some internal issue. Please, retry after sometime.",
      RSE_INVALID_PARAMETER_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      RSE_UNAUTHORIZED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      RSE_NOT_FOUND: "Record not found. Please try again later.",
      RSE_DB_FETCH_RECORD_ERROR:
        "Something went wrong while fetching records. Please try again later",
      RSE_DB_UPDATE_RECORD_ERROR:
        "Something went wrong while taking action. Please try again later",
      RSE_INVALID_WORKFLOW_TRIGGERED_ERROR:
        "Something went wrong while taking action. Please try again later",
      RSE_RECORD_DUPLICATION:
        "Record with similar action already exists. Please try refreshing your view.",
      ...this.getHandledErrorMessages(),
    };
    (params?.options?.serviceOrder || []).reverse()?.forEach((cls) => {
      if (typeof cls.getErrorMessages === "function") {
        const codes = cls.getErrorMessages();
        errorCodes = {
          ...errorCodes,
          ...codes,
        };
      }
    });
    return errorCodes;
  }

  static generateEntity(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}entity`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static reviewEntity(payload, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}entity`,
        method: "PUT",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postEntityData(request) {
    return this.apiCall({
      url: `${servicePrefix}entity`,
      data: JSON.stringify(request),
      method: "POST",
    });
  }
}

export default ReviewService;
