/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { validateDropdownValue } from "@onlinesales-ai/util-methods-v2";
import { UniversalDropdown } from "@onlinesales-ai/dropdown-v2";
import { Text } from "@onlinesales-ai/label-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import FormWrapper from "../../FormWrapper";

const UniversalDropdownForm = (props) => {
  const {
    formErrors,
    title,
    isEditable,
    dataKey,
    showErrors,
    options: pOptions,
    formValues,
    onChange,
    dataKeyForObject,
    defaultPlaceholder,
    isMulti,
    setFirstIfEmpty,
    validations,
    errorTitle,
    onError,
    originalValueDataKey,
    dataKeyPrefix,
    showReadOnlyInNewLine,
    isLoading,
    supportedOptions,
    showReadyOnlyDropdown,
    readOnlyTooltip,
    renderNonEditText: pRenderNonEditText,
  } = props;

  const options = useMemo(() => {
    if (supportedOptions?.length) {
      return pOptions.filter((op) => supportedOptions.includes(op.value));
    }

    return pOptions;
  }, [pOptions, supportedOptions]);

  const dropdownProps = {
    searchPlaceholder: props.searchPlaceholder,
    isMulti: props.isMulti,
    isShowPillsAsValues: props.isShowPillsAsValues,
    isClearable: props.isClearable,
    showMoreAndShowLess: props.showMoreAndShowLess,
    isSearchable: props.isSearchable,
    isEditable: props.isEditable,
    isShowSelectAll: props.isShowSelectAll,
    selectAllOption: props.selectAllOption,
    showTotalSelected: props.showTotalSelected,
    entityName: props.entityName,
    showShadow: props.showShadow,
    showfullBorder: props.showfullBorder,
    isFetchError: props.isFetchError,
    errorDetails: props.errorDetails,
    enableborderbottom: props.enableborderbottom,
    dropdownAlignRight: props.dropdownAlignRight,
    menuPlacement: props.menuPlacement,
    placeholder: props.placeholder,
    fetchAsyncOptions: props.fetchAsyncOptions,
    originalValueDataKey: props.originalValueDataKey,
    dataKeyPrefix: props.dataKeyPrefix,
    cacheOptions: props.cacheOptions,
    initialOptions: props.initialOptions,
    onReload: props.onReload,
    components: props.components,
    optionTemplate: props.optionTemplate,
    enableVirtualScroll: props.enableVirtualScroll,
    size: props.size,
    showCommaSepratedValue: props.showCommaSepratedValue,
    showTooltip: props.showTooltip,
    menuPosition: props.menuPosition,
    flipMenu: props.flipMenu,
    flipVirtualMenu: props.flipVirtualMenu,
    containerClass: props.containerClass,
    formatOptionLabel: props.formatOptionLabel,
    tooltipClass: props.tooltipClass,
    customOptionTemplates: props.customOptionTemplates,
    customMenuPosition: props.customMenuPosition,
    isLoading: props.isLoading,
    formGroupInnerClassName: props.formGroupInnerClassName,
    defaultPillPlaceholderTag: props.defaultPillPlaceholderTag,
    dropdownPillWrapperClass: props.dropdownPillWrapperClass,
    isOuterLoading: props.isOuterLoading,
    customLabel: props.customLabel,
    selectedOptionsLabelProps: props.selectedOptionsLabelProps,
    isDisabled: props.isDisabled,
    doNotShowPillsAsDisabled: props.doNotShowPillsAsDisabled,
    containerClassName: props.containerClassName,
    pillVariant: props.pillVariant,
    isShowPillsInDropdown: props?.isShowPillsInDropdown,
    getCheckboxValueDom: props.getCheckboxValueDom,
    enableShadowScroll: props.enableShadowScroll,
    emptyBoxMessage: props.emptyBoxMessage,
    styles: props.styles,
    checkBoxLabelProps: props.checkBoxLabelProps,
  };

  if (typeof props.defaultOptions !== "undefined") {
    dropdownProps.defaultOptions = props.defaultOptions;
  }

  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [formValues, dataKey]);

  const objectValue = useMemo(() => {
    return dataKeyForObject ? _get(formValues, dataKeyForObject) : null;
  }, [formValues, dataKeyForObject]);

  const onChangeDropdown = (selectedValue, actionMeta, originalValue) => {
    const val = { [dataKey]: selectedValue };

    if (originalValueDataKey) {
      val[`${dataKeyPrefix || ""}${originalValueDataKey}`] = originalValue;
    }

    if (dataKeyForObject) {
      val[`${dataKeyPrefix || ""}${dataKeyForObject}`] = originalValue.optionData;
    }

    onChange(val);
  };

  useEffect(() => {
    if (setFirstIfEmpty && options?.length && _isEmpty(value)) {
      onChangeDropdown(options[0].value, {}, options[0]);
    }
  }, []);

  const validate = () => {
    const validationValue = validateDropdownValue({
      validations,
      value,
      title,
      errorTitle,
    });

    onError({ [dataKey]: validationValue?.errorMsg });
  };

  useEffect(() => {
    validate();
  }, [value]);

  const renderNonEditText = () => {
    if (pRenderNonEditText) {
      return pRenderNonEditText(value, objectValue);
    }

    let nonEditValue = defaultPlaceholder || "";

    if (isMulti && value) {
      nonEditValue = value.map((val) => options?.find?.((op) => op.value === val)?.label || val);

      if (showReadOnlyInNewLine) {
        nonEditValue = nonEditValue.map((v) => (
          <>
            {v}
            <br />
          </>
        ));
      } else {
        nonEditValue = nonEditValue.join(", ");
      }
    } else if (!isMulti) {
      const labelValue = options?.find?.((op) => op.value === value)?.label;

      if (labelValue) {
        nonEditValue = labelValue;
      }

      if (!labelValue && value) {
        nonEditValue = value;
      }
    }

    return nonEditValue;
  };

  return (
    <FormWrapper
      {...props}
      hasError={showErrors && formErrors[dataKey]}
      error={formErrors[dataKey]}
    >
      {isEditable ? (
        <UniversalDropdown
          {...dropdownProps}
          options={options}
          value={value}
          onChange={onChangeDropdown}
        />
      ) : (
        <>
          {isLoading ? (
            <div className="animated-bg universal-dropdown-loader non-editable" />
          ) : (
            <>
              {showReadyOnlyDropdown ? (
                <WithTooltip title={readOnlyTooltip} placement="top">
                  <div>
                    <UniversalDropdown
                      {...dropdownProps}
                      options={options}
                      isDisabled={!isEditable}
                      value={value}
                      onChange={onChangeDropdown}
                    />
                  </div>
                </WithTooltip>
              ) : (
                <Text showEllipsis className="mh-auto" title={renderNonEditText()}>
                  <div className="non-edit-mode-text">{renderNonEditText()}</div>
                </Text>
              )}
            </>
          )}
        </>
      )}
    </FormWrapper>
  );
};

UniversalDropdownForm.defaultProps = {
  dataKey: null,
  dataKeyPrefix: "",
  title: "",
  formGroupClassName: "form-component-dropdown",
  formGroupInnerClassName: "",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please select __FIELD_TITLE__",
    },
  ],
};

export default UniversalDropdownForm;
